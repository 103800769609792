/* eslint-disable no-case-declarations */
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Container } from 'reactstrap';

import Login from 'shared/components/Login';
import SEO from 'shared/components/SEO';
import { useUser } from 'shared/context/UserContext';

function LoginPage() {
  const { user } = useUser();
  const { push } = useRouter();

  useEffect(() => {
    if (user && !user.guest) push(`/`);
  }, [user]);

  return (
    <Container className="mt-4">
      <SEO
        title="inloggen"
        description="Voor een betere winkelervaring, log je hier in"
        canonical="/login"
      />
      <div className="mt-5">
        <Login />
      </div>
    </Container>
  );
}

export default LoginPage;
