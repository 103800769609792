/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from 'react';
import { Button, Alert, Form, FormGroup, Input, FormFeedback, Label } from 'reactstrap';
import Link from 'next/link';
import Nprogress from 'nprogress';
import validateEmailAddress from '../utils/validateEmailAddress';
import { useUser } from '../context/UserContext';
import { signin } from '../api/user';

function Login({ signup }: {signup?: boolean}) {
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  });
  const { refetchUser } = useUser();
  const [touchedFields, setTouchedFields] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [submitting, setSubmitting] = useState(false);

  const { password, email } = inputs;

  useEffect(() => {
    if (errorMessage) {
      const timer1 = setTimeout(() => setErrorMessage(null), 3000);
      return () => clearTimeout(timer1);
    }
  }, [errorMessage]);

  async function submitHandler(e) {
    e.preventDefault();
    Nprogress.start();
    setErrorMessage(null);
    setSubmitting(true);
    try {
      await signin({ email, password, app: process.env.NEXT_PUBLIC_APP });
      setSubmitting(false);
      refetchUser();
    } catch (error) {
      setSubmitting(false);
      setErrorMessage('Login mislukt');
    }
    Nprogress.done();
  }

  const handleInputChange = ({ target: { name, value } }) => {
    if (touchedFields.indexOf(name) === -1) {
      setTouchedFields((prevTouchedFields) => [...prevTouchedFields, name]);
    }
    setInputs({ ...inputs, [name]: value });
  };

  return <>
    <Form
      onSubmit={submitHandler}
      autoComplete="on"
      style={{ maxWidth: 500 }}
      className="d-block mx-auto"
    >
      <fieldset disabled={submitting}>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            autoComplete="email"
            required
            invalid={
              !!(touchedFields.indexOf('email') !== -1 && !validateEmailAddress(inputs.email))
            }
            valid={
              !!(touchedFields.indexOf('email') !== -1 && validateEmailAddress(inputs.email))
            }
            placeholder="Email"
            value={email}
            onChange={handleInputChange}
          />
          <FormFeedback>Vul een geldig emailadres in</FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label for="password">Wachtwoord</Label>
          <Input
            type="password"
            name="password"
            id="password"
            autoComplete={signup ? 'new-password' : 'current-password'}
            required
            value={password}
            invalid={touchedFields.indexOf('password') !== -1 && !password}
            onChange={handleInputChange}
          />

          <FormFeedback>Vul een wachtwoord in</FormFeedback>
        </FormGroup>
        {errorMessage && (
          <Alert color="danger" isOpen>
            <span className="d-block">{errorMessage}</span>
          </Alert>
        )}
        <Button
          type="submit"
          color="success"
          disabled={!password || !validateEmailAddress(email)}
        >
          Inloggen
        </Button>
        <p className="mt-4">
          Nog geen account? Maak er{' '}
          <Link href="/signup">
            hier
          </Link>{' '}
          1 aan.
        </p>
        <p className="mt-4">
          <Link href="/password-reset-request">
            Wachtwoord vergeten?
          </Link>
        </p>
      </fieldset>
    </Form>
  </>;
}

export default Login;
